<!-- eslint-disable max-len -->
<template>
	<div>
		<modal v-model="isDuplicateGoal">
			<template #body>
				<div class="px-7 pb-7">
					<h6 class="text-center text-h6 font-weight-bold">
						You already have this type of goal in your goal list.
					</h6>
				</div>
			</template>
		</modal>

		<!-- complete goal -->
		<modal v-model="isSureToCompleteGoal" @close="resetGoal">
			<template #body>
				<div class="d-flex flex-column px-5 pb-5 mx-auto">
					<div class="mx-auto">
						<board-icon />
					</div>
					<h5 class="text-center text-h5 font-weight-bold mb-4">Congratulations</h5>
					<span class="text--secondary text-center text-base mb-4">
						Are you sure want to complete the goal?
					</span>

					<div class="d-flex align-center justify-center">
						<v-btn
							large
							rounded
							outlined
							type="button"
							color="primary"
							class="ma-2 font-weight-bold"
							@click="
								isSureToCompleteGoal = false
								resetGoal()
							"
							>No</v-btn
						>
						<v-btn
							large
							rounded
							type="button"
							color="primary"
							class="ma-2 font-weight-bold"
							@click="
								isSureToCompleteGoal = false
								updateCompleteGoal()
							"
							>Yes</v-btn
						>
					</div>
				</div>
			</template>
		</modal>

		<!-- delete goal -->
		<modal v-model="isSureToDeleteGoal">
			<template #body>
				<div class="d-flex flex-column px-5 pb-5 mx-auto">
					<h6 class="text--secondary text-center text-h6 font-weight-bold mb-4">
						Are you sure want to delete the goal?
					</h6>

					<div class="d-flex align-center justify-center">
						<v-btn
							large
							rounded
							outlined
							type="button"
							color="primary"
							class="ma-2 font-weight-bold"
							@click="
								isSureToDeleteGoal = false
								updateGoalId = null
							"
							>No</v-btn
						>
						<v-btn
							large
							rounded
							type="button"
							color="primary"
							class="ma-2 font-weight-bold"
							@click="
								isSureToDeleteGoal = false
								handleDeleteGoal()
							"
							>Yes</v-btn
						>
					</div>
				</div>
			</template>
		</modal>

		<v-dialog v-model="isOpenGoalDialog" persistent>
			<v-card elevation="0" class="pb-6">
				<v-toolbar flat class="mb-3">
					<v-toolbar-title
						><strong
							>{{ goalAction === 'ADD' ? 'Add Goal' : 'Update Goal' }}
						</strong></v-toolbar-title
					>
					<v-spacer></v-spacer>
					<v-btn
						icon
						color="secondary"
						@click="
							isOpenGoalDialog = false
							resetGoal()
						"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar>

				<v-form
					class="px-5 d-flex flex-column rounded-small"
					ref="goalForm"
					@submit.prevent="handleGoal"
				>
					<v-row>
						<v-col cols="8">
							<label for="select" class="d-inline-block mb-1 font-weight-bold">Select</label>
							<v-select
								outlined
								id="select"
								class="rounded-small"
								:items="goalTypeOptions"
								item-text="name"
								item-value="id"
								v-model="goal.goalId"
								:rules="[(v) => !!v || 'Goal name is required']"
							/>
						</v-col>
						<v-col cols="4">
							<label for="set-goal" class="d-inline-block mb-1 font-weight-bold">Set Goal</label>
							<v-text-field
								outlined
								id="set-goal"
								class="rounded-small"
								v-model="goal.goalSet"
								:rules="[(v) => !!v || 'Goal unit is required']"
							/>
						</v-col>
					</v-row>

					<v-btn
						rounded
						type="submit"
						color="primary"
						class="pa-3 mt-auto h-auto w-full d-block rounded-small"
					>
						<strong class="text-capitalize text-h6 font-weight-bold">{{
							goalAction === 'ADD' ? 'Add' : 'Update'
						}}</strong>
					</v-btn>
				</v-form>
			</v-card>
		</v-dialog>

		<v-card elevation="0" class="min-height-screen pb-4">
			<v-toolbar flat class="border-b-2 border--secondary mb-4 px-0 py-3 !h-85px">
				<v-toolbar-title><strong>My Goals</strong></v-toolbar-title>
			</v-toolbar>

			<transition name="fade" mode="out-in">
				<div
					v-if="isGoalsLoading"
					class="d-flex align-center justify-center w-full h-full"
					style="height: 400px"
				>
					<loader />
				</div>
				<div v-else class="px-3 h-full">
					<div v-if="hasGoals && !hasError">
						<v-card
							v-for="goal in goals"
							:key="goal.id"
							flat
							elevation="0"
							class="pa-2 border-2 border--secondary mt-4 rounded-small"
						>
							<div class="d-flex justify-space-between align-start pa-2">
								<div class="">
									<h3 class="text-base font-weight-bold mb-0 leading-normal">
										{{ goal.goalName }}
									</h3>
									<span class="text--secondary text-sm">{{ goal.goalSet }}</span>
								</div>
								<div v-if="!goal.isCompleted">
									<v-menu bottom left>
										<template v-slot:activator="{ on, attrs }">
											<v-btn icon v-bind="attrs" v-on="on">
												<v-icon>mdi-dots-vertical</v-icon>
											</v-btn>
										</template>

										<v-list>
											<v-list-item @click="editGoal(goal)">
												<v-list-item-title>Edit</v-list-item-title>
											</v-list-item>
											<v-list-item
												@click="
													isSureToDeleteGoal = true
													goalId = goal.id
												"
											>
												<v-list-item-title>Delete</v-list-item-title>
											</v-list-item>
										</v-list>
									</v-menu>
								</div>
							</div>

							<div class="d-flex justify-space-between align-center pa-2">
								<a
									class="font-weight-bold d-inline-block text-sm text-decoration-none text-uppercase"
									:href="`${baseUrl}/products/goal/${goal.id}?token=${authToken}`"
									><strong>Suggest Product</strong></a
								>
								<button
									type="button"
									class="rounded-small border-2 py-1 px-3 text-sm"
									:class="{
										'border--primary font-weight-bold cursor-default': goal.isCompleted,
										'border--secondary cursor-pointer': !goal.isCompleted,
									}"
									@click="handleCompleteGoal(goal)"
								>
									<span v-if="goal.isCompleted" class="mr-2"><check-icon /></span>
									<span class="primary--text">{{
										goal.isCompleted ? 'Completed' : 'Complete'
									}}</span>
								</button>
							</div>
						</v-card>
					</div>
					<div
						v-else
						class="d-flex h-full flex-column justify-center align-center"
						style="height: 500px"
					>
						<span v-if="!hasGoals && !hasError" class="text-base text-secondary mb-3"
							>Looks like you don't have any goals.</span
						>
						<span v-else class="text-base text-secondary mb-3">Oops!! Something went wrong.</span>
					</div>
				</div>
			</transition>
		</v-card>

		<!-- add goal fixed button -->
		<v-btn
			fixed
			bottom
			right
			rounded
			fab
			color="primary"
			@click="
				goalAction = 'ADD'
				isOpenGoalDialog = true
			"
			><v-icon class="white--text">mdi-plus</v-icon></v-btn
		>
	</div>
</template>

<script>
import AuthMixin from '@/mixins/auth'
import Loader from '@/components/Loader.vue'
import CheckIcon from '@/components/icons/CheckIcon.vue'
import currencyFormatter from '@/utils/currency-formatter'
import {
	getAllGoals,
	getAllGoalNames,
	createOrUpdateGoal,
	completeGoal,
	deleteGoal,
} from '@/services/goal'
import { SUCCESS, EXISTS, NOT_FOUND } from '@/constants/status-code'

const Modal = () => import('@/components/Modal.vue')
const BoardIcon = () => import('@/components/icons/BoardIcon.vue')

export default {
	name: 'GoalsPage',
	mixins: [AuthMixin],
	components: {
		Loader,
		Modal,
		BoardIcon,
		CheckIcon,
	},
	data() {
		return {
			currencyFormatter,
			hasError: false,
			isGoalsLoading: false,
			isOpenGoalDialog: false,
			isDuplicateGoal: false,
			isSureToDeleteGoal: false,
			isSureToCompleteGoal: false,
			goalAction: null, // 'ADD' | 'UPDATE'
			goal: {
				goalId: null,
				goalSet: null,
			},
			goalId: null,
			goals: [],
			goalTypeOptions: [],
		}
	},
	created() {
		Promise.all([this.fetchGoals(), this.fetchGoalNames()]).catch(() => {
			this.hasError = true
		})
	},
	computed: {
		hasGoals() {
			return Array.isArray(this.goals) && this.goals.length
		},
	},
	methods: {
		resetGoal() {
			this.goalId = null
			this.goalAction = null
			this.goal.goalId = null
			this.goal.goalSet = null

			if (this.$refs.goalForm) {
				this.$refs.goalForm.reset()
			}
		},
		editGoal(editGoal) {
			const goal = { ...editGoal }
			this.goal = goal
			this.goalId = goal.id
			this.goalAction = 'UPDATE'
			this.isOpenGoalDialog = true
		},
		handleCompleteGoal(editGoal) {
			if (editGoal.isCompleted) return
			const goal = { ...editGoal }
			this.goalId = goal.id
			this.isSureToCompleteGoal = true
		},
		handleGoal() {
			if (!this.$refs.goalForm.validate()) return

			if (this.goalAction === 'ADD') this.handleAddGoal()
			if (this.goalAction === 'UPDATE') this.handleUpdateGoal()
		},
		async fetchGoals() {
			this.isGoalsLoading = true

			const res = await getAllGoals(this.authToken)
			const { data, statusCode } = res.data

			if (statusCode !== SUCCESS && statusCode !== NOT_FOUND) {
				this.isGoalsLoading = false
				throw new Error('Error fetching goal')
			}

			this.goals = data

			this.isGoalsLoading = false
		},
		async fetchGoalNames() {
			const res = await getAllGoalNames(this.authToken)
			const { data, statusCode } = res.data

			if (statusCode !== SUCCESS && statusCode !== NOT_FOUND) {
				throw new Error('Error fetching goal names')
			}

			this.goalTypeOptions = data
		},
		async handleAddGoal() {
			// reset
			this.isOpenGoalDialog = false
			try {
				const res = await createOrUpdateGoal(this.authToken, { formData: this.goal })
				const { statusCode } = res.data

				if (statusCode === EXISTS) {
					this.isDuplicateGoal = true
				}

				if (statusCode === SUCCESS) {
					this.fetchGoals()
				}
			} catch (e) {
				console.log(e, 'error')
			}
			this.resetGoal()
		},
		async handleUpdateGoal() {
			const formData = {
				id: this.goalId,
				goalId: this.goal.goalId,
				goalSet: this.goal.goalSet,
			}
			// reset
			this.isOpenGoalDialog = false
			try {
				const res = await createOrUpdateGoal(this.authToken, { formData })
				const { statusCode } = res.data

				if (statusCode === EXISTS) {
					this.isDuplicateGoal = true
				}

				if (statusCode === SUCCESS) {
					this.fetchGoals()
				}
			} catch (e) {
				console.log(e, 'error')
			}
			this.resetGoal()
		},
		async updateCompleteGoal() {
			try {
				const res = await completeGoal(this.authToken, { goalId: this.goalId })
				const { statusCode } = res.data

				if (statusCode === SUCCESS) {
					// this.fetchGoals()
					// eslint-disable-next-line no-param-reassign
					this.goals = this.goals.map((goal) => {
						if (goal.id === this.goalId) {
							return { ...goal, isCompleted: true }
						}
						return goal
					})
				}
			} catch (e) {
				console.log(e, 'error')
			}
			this.resetGoal()
		},
		async handleDeleteGoal() {
			if (!this.goalId) return

			try {
				const res = await deleteGoal(this.authToken, { goalId: this.goalId })
				const { statusCode } = res.data

				if (statusCode === SUCCESS) {
					this.fetchGoals()
				}
			} catch (e) {
				console.log(e, 'error')
			}
			this.goalId = null // null update goal id
		},
	},
}
</script>
